import axios from "axios";
import { ACCESSTOKEN } from "../../helpers/ActionUrl";
export const SET_CURRENCY = "SET_CURRENCY";

export const setCurrency = currencyName => {
  return dispatch => {
    console.log('ACCESSTOKEN',ACCESSTOKEN);
    // pass accessToken For Login_User
    let config = {}
    if(ACCESSTOKEN){
      config = {
        headers : {
          Authorization : `Bearer ${ACCESSTOKEN}`

        }
      }
    }
    axios
      .get(`https://api.exchangeratesapi.io/latest?base=INR`,config)
      .then(response => {
        const rates = response.data.rates;
        let currencyRate = 0;
        for (const rate in rates) {
          if (rate === currencyName) {
            currencyRate = rates[rate];
          }
        }
        dispatch({
          type: SET_CURRENCY,
          payload: { currencyName, currencyRate }
        });
      })
      .catch(err => {
        console.log("Error: ", err);
      });
  };
};
