export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductSize,
  selectedProductDetail,
  productPrice
) => {
  // debugger;
  
  console.log("item", item);
  console.log("addToast", addToast);
  console.log("quantityCount", quantityCount);
  console.log("selectedProductColor", selectedProductColor);
  console.log("selectedProductSize", selectedProductSize);
  console.log("selectedProductDetail", selectedProductDetail);
  return (dispatch) => {
    if (addToast) {
      addToast("Added To Cart", { appearance: "success", autoDismiss: true });
    }
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
          ? item.selectedProductColor
          : null,
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
          ? item.selectedProductSize
          : null,
        selectedProductDetail: selectedProductDetail
          ? selectedProductDetail
          : null,
        productPrice: productPrice ? productPrice : null,
      },
    });
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  // debugger
  return (dispatch) => {
    
    if (addToast) {
      addToast("Item Decremented From Cart", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};
//delete all from cart
export const deleteAllFromCart = (addToast) => {
  // debugger
  return (dispatch) => {
    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item, color = "red", size = "M") => {
  console.log("itemincartaction", item);
  console.log("itemincartactioncolor", color);
  console.log("itemincartactionsize", size);
  if (item.stock) {
    return item.stock;
  }
  //  else {
  //   return item.variation
  //     .filter((single) => single.color === color)[0]
  //     ?.size.filter((single) => single.name === size)[0].stock;
  // }
};
