// let URL = "http://127.0.0.1:8000/api";
const URL = "https://desapi.descomponents.com/public/api";
export const IMAGE_URL = "https://desapi.descomponents.com/public";

//Login API
export const LOGIN = URL + "/login";
export const ACCESSTOKEN = localStorage.getItem("accessToken");
// export const ADDREGISTER = URL + "/register";
export const ADDREGISTER = URL + "/addUser";

export const GETALLPRODUCTS = URL + "/getAllProducts";
export const GETALLPRODUCTCATEGORIES = URL + "/getAllProductCategories";
export const GETPRODUCTBYID = URL + "/getProductById";
export const GETPRODUCTBYCATEGORYID = URL + "/getProductByCategoryId";
export const ADDTOCART = URL + "/addCart";
export const ADDORDER = URL + "/addOrder";
export const ADDCONTACTINQUIRYMAIL = URL + "/addContactInquiryMail";
